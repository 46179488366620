import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';  
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor { 

    endsPointsToSkipLoader = [
        "/api/Home", 
        "/api/Lookups/countries", 
        "/api/Lean/Lean/Verify", 
    ];
 
    constructor(private laoder: NgxSpinnerService) {}  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  


        // FOR TESTING 
        !this.endsPointsToSkipLoader.includes(req.url) && this.laoder.hide(); 
        return next.handle(req).pipe(finalize(() => this.laoder.hide()));  
    } 
}