
<div class="container">
    <div class="logo text-center">
        <img *ngIf="currentLanguage == 'en' " style="cursor: pointer;" [src]="headerImgPath + 'logoEnBlack.svg'" width="120px">
        <img *ngIf="currentLanguage == 'ar' " style="cursor: pointer;" [src]="headerImgPath + 'logoArBlack.svg'" width="120px">
    </div>
    
    <div class="text text-center">
        <h3 class="f-b-600 primary-text-black-dark " > {{ 'pleaseBeAwareThatTheLaunchOfTheServiceIsOnlyForTrial' | translate }} </h3>
    </div>
    
    <div class="button text-center">
        <button (click)="dialog.close()" class="main-btn px-5 "> {{ 'ApprovalAndContinuation' | translate }} </button>
    </div> 
</div>
