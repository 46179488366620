<nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">

        <div class="con"   >
            <img class="navbar-brand" *ngIf="currentLanguage == 'en' " style="cursor: pointer;"
                [src]="headerImgPath + 'logoEnBlack.svg'" width="120px" [routerLink]="'/'">
            <img class="navbar-brand" *ngIf="currentLanguage == 'ar' " style="cursor: pointer;"
                [src]="headerImgPath + 'logoArBlack.svg'" width="120px" [routerLink]="'/'">
    
    
            <div (click)="openExperimentalLaunchDialog()" class="experimental-launch">
                <p class="pt-2" > {{ 'ExperimentalLaunch' | translate }} </p>
            </div>

        </div>
        
        <span></span>
        <span></span>
        
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse navbar-nav me-auto mb-2 mb-lg-0 " id="navbarSupportedContent">

            <div class="navbar-nav me-auto mb-2 mb-lg-0">
                <button class="right-side_login-btn main-btn" *ngIf="isUserLoggedIn" routerLink="/profile">
                    {{ 'goProfile' | translate }}
                </button>
                <button class="right-side_login-btn main-btn" *ngIf="!isUserLoggedIn" (click)="isCloased = false">
                    {{ 'login' | translate }}
                </button>
                <span class="px-2"></span>
                <div class="language cursor-pointer" (click)="changeLanguage()"
                    [dir]="currentLanguage != 'en' ?  'ltr' : 'rtl' ">
                    <img [src]="iconsImgPath + 'languageIcon.svg'">
                    <span> {{ currentLanguage != 'en' ? ( 'english' | translate ) : ( 'arabic' | translate ) }} </span>
                </div>
            </div>
        </div> 



    </div>
</nav>



<section class="auth" *ngIf="!isCloased">
    <ess-auth @fadeInUp (close)="isCloased = $event" (isLoggedIn)="checkUserLoggedIn()"
        (click)="$event.stopPropagation"></ess-auth>
</section>