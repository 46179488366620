import { AppType } from "src/app/__core/enums/appType"; 

export const environment = {
  production: true,
  paymentUrl : 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=',
  
  username: 'admin',
  password: 'P@ssw0rd',
  prod_swagger: "https://ammni.com/apis/swagger/index.html",
  baseUrl: "https://ammni.com/apis/api/", 
  appName: 'Ammeni',
  appType: AppType.WEBSITE
};

 